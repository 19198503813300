<template>
  <v-data-table
    :headers="headers"
    :items="payments"
    :sort-by="[{ key: '#', order: 'asc' }]"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title
          class="text-h6 text-uppercase font-weight-bold text--darken-1 justify-center text-primary"
          >Payments</v-toolbar-title
        >

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ props }">
            <v-text-field
              v-model="search"
              density="compact"
              variant="outlined"
              label="Search"
              append-inner-icon="mdi-magnify"
              class="ma-3"
              single-line
              hide-details
            ></v-text-field>
            <v-btn
              class="mb-2"
              dark
              size="large"
              prepend-icon="mdi-plus-thick"
              variant="elevated"
              v-bind="props"
              disabled
              color="primary"
            >
              New Payment
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span
                class="text-caption text-uppercase font-weight-bold text--darken-1 justify-center text-primary"
                >{{ formTitle }}</span
              >
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="isValid" @submit.prevent="save">
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        v-model="editedItem.title"
                        label="Titile"
                        color="primary"
                        :rules="rules.fullName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        v-model="editedItem.slug"
                        label="Slug"
                        color="primary"
                        :rules="rules.email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        v-model="editedItem.cost"
                        label="Cost"
                        color="primary"
                        :rules="rules.cost"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-autocomplete
                        v-model="editedItem.academicLevelId"
                        label="Select Academic Level"
                        :items="academicLevels"
                        item-value="id"
                        item-text="title"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-textarea
                        v-model="editedItem.description"
                        label="Descriptions"
                        color="primary"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions class="mr-7 mt-n7 mb-4">
              <v-spacer></v-spacer>
              <v-btn color="error" variant="outlined" @click="close">
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                variant="outlined"
                :disabled="!isValid"
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="mx-10">
            <v-card-title class="text-subtitle-2 text-center text-primary">
              You sure you want to delete this user?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" variant="outlined" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                variant="outlined"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.id`]="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <div v-if="item.status === 'COMPLETED'" class="text-success">
        {{ item.status }}
      </div>
      <div v-else class="text-error">
        {{ item.status }}
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        class="me-2"
        color="primary"
        size="x-small"
        variant="outlined"
        icon="mdi-pencil"
        disabled
        @click="editItem(item)"
      ></v-btn>
      <v-btn
        class="me-2"
        color="error"
        size="x-small"
        icon="mdi-delete"
        variant="outlined"
        disabled
        @click="deleteItem(item)"
      ></v-btn>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" variant="outlined" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import PaymentService from "../services/payment.service.js";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    isValid: false,
    rules: {
      fullName: [
        (value) => {
          if (value?.length >= 3) return true;
          return "You must enter a full name.";
        },
      ],
      email: [
        (value) => {
          if (value?.length >= 1) return true;
          return "You must enter a slug.";
        },
      ],
      cost: [
        (value) => {
          if (value) return true;
          return "You must enter a cost.";
        },
      ],
    },
    headers: [
      {
        title: "#",
        value: "id",
        align: "start",
        sortable: false,
        key: "id",
      },
      { title: "Transaction Id", value: "transactionId", key: "transactionId" },
      { title: "Amount", value: "amount", key: "amount" },
      { title: "Currency", value: "currency", key: "currency" },
      { title: "Status", value: "status", key: "status" },
      { title: "Order Id", value: "orderId", key: "orderId" },
      {
        title: "Order Title",
        value: "order.title",
        key: "order.title",
      },
      { title: "Payment Method", value: "paymentMethod", key: "paymentMethod" },
      { title: "Actions", key: "actions", sortable: false },
    ],

    // 'orderId' => $this->order,

    status: [
      {
        title: "Active",
        value: 1,
      },
      {
        title: "Deactivated",
        value: 0,
      },
    ],

    editedIndex: -1,
    payments: [],
    academicLevels: [],
    editedItem: {
      name: "",
      email: "",
      active: 1,
      description: "",
    },
    defaultItem: {
      name: "",
      email: "",
      active: 0,
      description: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Payment" : "Edit Payment";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    // this.getSubcategories();
    this.getPayments();
  },

  methods: {
    async validate() {
      this.isValid = await this.$refs.form.validate();
    },

    getSubcategories() {
      return paymentservice.index().then(
        (response) => {
          if (response.status == 200) {
            this.payments = response.data.data;
            this.loading = false;
          } else {
            this.payments = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.payments = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getPayments() {
      return PaymentService.index().then(
        (response) => {
          if (response.status == 200) {
            this.payments = response.data.data;
            this.loading = false;
          } else {
            this.payments = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.payments = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    editItem(item) {
      this.editedIndex = this.payments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.payments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      paymentservice.delete(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.payments.splice(this.editedIndex, 1);
            this.closeDelete();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.payments[this.editedIndex], this.editedItem);
          paymentservice.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
                this.loading = false;
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          paymentservice.store(this.editedItem).then(
            (response) => {
              if (response.status == 201) {
                this.payments.push(this.editedItem);
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>