// payment-service.js
import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class PaymentService {
    createPayment(orderId) {
        return axios.post(API_URL + 'paypal/create-payment', { orderId: orderId }, { headers: authHeader() });
    }

    capturePayment(token, orderId) {
        return axios.post(API_URL + 'paypal/capture-payment', { token, orderId: orderId }, { headers: authHeader() });
    }

    index() {
        return axios.get(API_URL + 'get-all-payments', { headers: authHeader() })
    }

    cancelPayment(orderId) {
        return this.post(`/payment/cancel`, { orderId }, { headers: authHeader() });
    }
}

export default new PaymentService();
