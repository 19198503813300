<template>
  <v-container>
    <v-card elevation="0" class="pa-4">
      <v-card-title class="headline">Payment Cancelled</v-card-title>
      <v-card-text>
        <p>Your payment has been cancelled. If this was a mistake, please try again.</p>
        <v-row>
          <v-col cols="12">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Order ID: {{ orderId }}</v-list-item-title>
                  <v-list-item-subtitle>Cancelled on: {{ formattedDate }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Project Title: {{ projectTitle }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="retryPayment">Retry Payment</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="goToHome">Go to Home</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      orderId: this.$route.query.orderId || 'N/A',
      projectTitle: this.$route.query.projectTitle || 'N/A',
    };
  },
  computed: {
    formattedDate() {
      return new Date().toLocaleString();
    },
  },
  methods: {
    retryPayment() {
      this.$router.push('/');
    },
    goToHome() {
      this.$router.push('/');
    },
  },
};
</script>